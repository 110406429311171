<template>
  <el-dialog
    :visible.sync="openPublishTaskModal"
    v-if="openPublishTaskModal"
    custom-class="editContentItemModal"
    destroy-on-close
    fullscreen
    @close="$emit('handleCancel')"
  >
    <!--    <el-scrollbar-->
    <!--      v-loading="loadingSnapShot"-->
    <!--      :native="false"-->
    <!--      style="margin-top: 34px"-->
    <!--    >-->

    <div>
      <el-form
        v-loading="loadingSnapShot || isSubmitting"
        :element-loading-text="getLoadingText || snapshotLoadingText"
        label-position="top"
        ref="publishTaskForm"
        label-width="100px"
        class="form-container"
        :rules="rules"
        :model="template"
      >
        <el-row type="flex" style="padding-top: 34px">
          <el-col :span="12" :offset="6">
            <page-header :title="__('Publish Task')"></page-header>
          </el-col>
        </el-row>
        <el-row type="flex" style="padding-bottom: 30px">
          <el-col
            :span="12"
            :offset="6"
            style="display: flex;align-items: center"
          >
            <TaskImageLibrary
              :image-path-prop="template.image_path"
              @image-selected="handleImageSelected"
            ></TaskImageLibrary>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item
              class="form_label_top"
              :label="__('Template Name')"
              prop="template_name"
            >
              <el-input
                v-model="template.template_name"
                id="templateNameInput"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item class="form_label_top" :label="__('Tags')">
              <TagsInput
                :tags="template.tags"
                @tag-update="handleTagUpdate"
                autocomplete
                :autocomplete-list="autocompleteTags"
              ></TagsInput>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item
              class="form_label_top"
              :label="__('Template Description')"
              prop="template_description"
            >
              <el-input
                type="textarea"
                v-model="template.template_description"
                :autosize="{ minRows: 4, maxRows: 6 }"
                id="templateDescriptionInput"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <responsive-container :md="12" :lg="12" :xl="12">
          <task-export-configure
            :task-stats="taskStats"
            :export-config="exportConfig"
            :task="task"
          />
        </responsive-container>
        <el-row type="flex">
          <el-col :span="6" :offset="6">
            <div class="input-output-variable-description">
              {{
                __(
                  "Select and add descriptions for any input and output variables that are required for this template to function as intended. These will be added to the template's description."
                )
              }}
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item
              class="form_label_top in-variables"
              :label="__('Input Variables')"
              prop="input_variables"
            >
              <input-output-variable-config
                v-for="(input_variable, index) in template.input_variables"
                :key="index"
                :index="index"
                :variable_list="input_variable"
                :variable_type="'input'"
                :selectedVariables="selectedInputVariables(template)"
                @change="inputOutputVariableChangeHandler($event, template)"
                @delete="
                  deleteInputOutputVariableHandler($event, index, template)
                "
                ref="inputChildComponent"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item
              class="form_label_top in-variables"
              :label="__('Output Variables')"
              prop="output_variables"
            >
              <input-output-variable-config
                v-for="(output_variable, index) in template.output_variables"
                :key="index"
                :index="index"
                :variable_list="output_variable"
                :variable_type="'output'"
                :selectedVariables="selectedOutputVariables(template)"
                @change="inputOutputVariableChangeHandler($event, template)"
                @delete="
                  deleteInputOutputVariableHandler($event, index, template)
                "
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="this.$auth.check('system')">
          <el-col :span="12" :offset="6">
            <el-form-item class="form_label_top" :label="__('Is System')">
              <el-checkbox v-model="template.is_system"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-button class="submitBtn" @click="submitForm"
                >{{ __("Publish") }}
              </el-button>
              <el-button class="cancelBtn" @click="$emit('handleCancel')"
                >{{ __("Cancel") }}
              </el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </div>
    <!--    </el-scrollbar>-->
  </el-dialog>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { mapActions } from "vuex";
import TaskImageLibrary from "@/views/build/tasks/create-task/components/TaskImageLibrary";
import TagsInput from "@/views/build/tasks/create-task/components/TagsInput";
import { templateNameExists } from "@/utils/formValidationRules";
import TaskExportConfigure from "@/views/build/tasks/create-task/components/TaskExport/TaskExportConfigure";
import ResponsiveContainer from "@/components/ResponsiveContainer";
import TaskImportExportRequest from "@/views/build/tasks/create-task/components/TaskExport/TaskImportExportRequest";
import _ from "lodash";
import InputOutputVariableConfig from "@/views/build/tasks/create-task/components/TaskExport/ContentConfigure/InputOutputVariableConfig.vue";
import InputOutputVariablesMixin from "@/views/build/tasks/create-task/components/TaskExport/InputOutputVariablesMixin.vue";

export default {
  name: "PublishTask",
  mixins: [BaseContent, TaskImportExportRequest, InputOutputVariablesMixin],
  components: {
    ResponsiveContainer,
    TaskExportConfigure,
    PageHeader,
    TaskImageLibrary,
    TagsInput,
    InputOutputVariableConfig
  },
  props: {
    task_id: {
      required: true,
      type: Number
    },
    template_image: {
      required: true,
      type: String
    },
    openModal: {
      required: true,
      type: Boolean
    },
    taskStats: {
      required: true,
      type: Object
    },
    exportConfig: {
      required: true,
      type: Object
    },
    task: {
      required: true,
      type: Object
    },
    loadingSnapShot: {
      required: true,
      type: Boolean
    },
    snapshotLoadingText: {
      type: String
    }
  },
  mounted() {
    this.template.image_path = this.template_image
      ? this.template_image
      : "task_image_1.svg";
  },
  data() {
    const validateTemplateName = async (rule, value, callback) => {
      let hasError = await templateNameExists(rule, value, this.template);
      if (hasError) {
        callback(__("Task name already exists"));
      } else {
        callback();
      }
    };
    return {
      template: {
        template_name: "",
        template_description: "",
        image_path: "",
        tags: "",
        input_variables: [],
        output_variables: [],
        is_system: 0
      },
      openPublishTaskModal: false,
      isSubmitting: false,
      inputVisible: false,
      inputValue: "",
      autocompleteTags: [
        { value: "Security" },
        { value: "Smart routing" },
        { value: "Surveys" },
        { value: "Hospitality" },
        { value: "Retail" },
        { value: "Marketing" }
      ],
      rules: {
        template_name: [
          {
            required: true,
            message: __("Template name cannot be empty"),
            trigger: "blur"
          },
          {
            max: 200,
            message: __("Length should be no more than 200 characters"),
            trigger: "blur"
          },
          {
            validator: validateTemplateName,
            trigger: "blur"
          }
        ],
        template_description: [
          {
            required: true,
            message: __("Template description cannot be empty"),
            trigger: "blur"
          },
          {
            max: 2000,
            message: __("Length should be no more than 2000 characters"),
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions("tasks", {
      publishTask: "publishTask"
    }),
    handleTagUpdate(tags) {
      this.template.tags = tags;
    },

    handleImageSelected(imagePath) {
      this.template.image_path = imagePath;
    },

    submitForm() {
      this.$refs.publishTaskForm.validate(valid => {
        if (valid && !this.checkVariableError(this.template)) {
          this.isSubmitting = true;
          let templateCopy = _.cloneDeep(this.template);
          this.cleanUpVariables(templateCopy);
          let template = { ...templateCopy, exportConfig: this.exportConfig };
          this.publishTask({ template, task_id: this.task_id, async: true })
            .then(data => {
              this.listenForTaskImportExportUpdates(
                data,
                this.task_id,
                this.onTaskPublishingCompletionEvent,
                this.onTaskPublishingErroredEvent
              );
            })
            .catch(err => {
              console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        } else {
          console.log("error submit");
          return false;
        }
      });
    },

    onTaskPublishingCompletionEvent(taskId, { request_id: requestId }) {
      this.stopListeningToTaskImportExportChannel(taskId, requestId);
      this.$message({
        message: __("Task published successfully"),
        type: "success"
      });
      this.isSubmitting = false;
      this.$emit("handleCancel");
    },

    onTaskPublishingErroredEvent(
      taskId,
      { request_id: requestId, request_info: requestInfo }
    ) {
      this.stopListeningToTaskImportExportChannel(taskId, requestId);
      this.$message({
        type: "error",
        message: _.get(requestInfo, "error") || __("Task publishing failed")
      });
      this.isSubmitting = false;
    }
  },
  created() {
    this.initialiseInputVariables(this.template);
    this.initialiseOutputVariables(this.template);
  },
  watch: {
    openModal: {
      immediate: true,
      handler: function(val) {
        this.openPublishTaskModal = val;
      }
    }
  }
};
</script>
<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
.form_label_top.in-variables {
  .variable-row:not(:first-child) {
    ::v-deep .row-item::before {
      display: none;
    }
  }
}
.input-output-variable-description {
  display: flex;
  flex-direction: column;
  color: #696969;
  word-break: break-word;
  padding-bottom: 15px;
}
</style>
